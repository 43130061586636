import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{
  canActivate(){
    var user = JSON.parse(localStorage.getItem('userDetails'));
    const isIntern = user.email==='dataintern@leverageedu.com'?true:false;
    if(isIntern){
      this.router.navigate(['data-management']);
    }
    return true;
  }

  constructor(private router:Router) { }
}
