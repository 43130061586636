import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../environments/environment';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";

@Injectable()
export class HttpClientService {
  // api base url 
  private baseUrl = environment.client

  constructor(private http: HttpClient) {}

  createAuthHeader(headers: HttpHeaders) {
    // headers.append('Api-Key', '34567890');
    // http://localhost:4200/auth/reset-password?token=QlIzaNnvm_bUS0HziXae5zMeHLrQFeQY_1515749972
  }

  // this function is used for sending get request
  get(url) {
    const headers = new HttpHeaders().set("Api-Key", "34567890"); // create HttpHeader object and set Api-Key in header
    return this.http.get(this.baseUrl + url, { headers }); // hitting http get request
  }

  // this function is used for sending delete request
  delete(url) {
    const headers = new HttpHeaders().set("Api-Key", "34567890"); // create HttpHeader object and set Api-Key in header
    return this.http.delete(this.baseUrl + url, { headers }); // hitting http get request
  }

  // this function is used for sending post request
  post(url, data) {
    const headers = new HttpHeaders().set("Api-Key", "34567890"); // create HttpHeader object and set Api-Key in header
    return this.http.post(this.baseUrl + url, data, { headers }); // hitting http post request
  }

  // this function is used for sending post request with auth key
  postWithAuthKey(url, data) {
    const user: any = localStorage.getItem("userDetails"); // getting user from local storage
    if (user) {
      // if user exist
      const auth_Key = JSON.parse(user); // parsing string to json
      const headers = new HttpHeaders()
        .set("Api-Key", "34567890")
        .set("Auth-Key", auth_Key.auth_key); // create HttpHeader object and set Api-Key and Auth Key in header

      return this.http.post(this.baseUrl + url, data, { headers }); // hitting http post request
    }
  }

  // this function is used for sending get request with auth key
  getWithAuthKey(url) {
    const user: any = localStorage.getItem("userDetails"); // getting user from local storage
    if (user) {
      const auth_Key = JSON.parse(user);
      const headers = new HttpHeaders()
        .set("Api-Key", "34567890")
        .set("Auth-Key", auth_Key.auth_key); // create HttpHeader object and set Api-Key and Auth Key in header

      return this.http.get(this.baseUrl + url, { headers }); // hitting http get request
    }
  }

  postWithAuthKeyAndFormData(url, data) {
    const user: any = localStorage.getItem("userDetails"); // getting user from local storage
    if (user) {
      const formData = new FormData(); // creating from data object for sending multipar form data request
      formData.append("file", data); // appending file
      const auth_Key = JSON.parse(user); // parsing json
      const headers = new HttpHeaders()
        .set("Api-Key", "34567890")
        .set("Auth-Key", auth_Key.auth_key); // create HttpHeader object and set Api-Key and Auth Key in header

      return this.http.post(this.baseUrl + url, formData, { headers }); // hitting http post request
    }
  }

  postWithFormData(url, data) {
    const formData = new FormData(); // creating from data object for sending multipar form data request
    formData.append("file", data); // appending file

    const headers = new HttpHeaders().set("Api-Key", "34567890"); // create HttpHeader object and set Api-Key  in header
    return this.http.post(this.baseUrl + url, formData, { headers }); // hitting http post request
  }

  getIPAddress() {
    // const headers = new HttpHeaders().set('Api-Key', '34567890');
    return this.http.get("http://freegeoip.net/json/?callback");
  }
}
