import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../environments/environment';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";

@Injectable()
export class HttpProductClientService {

  // api base url
  private baseUrl = environment.marketplace;
  
  constructor(private http: HttpClient) {}

  createAuthHeader(headers: HttpHeaders) {
    // headers.append('Api-Key', '34567890');
    // http://localhost:4200/auth/reset-password?token=QlIzaNnvm_bUS0HziXae5zMeHLrQFeQY_1515749972
  }

  // this function is used for sending get request
  get(url) {
    const user: any = localStorage.getItem("userDetails");  // getting user from local storage
    if (user) {
      const auth_Key = JSON.parse(user);
      const headers = new HttpHeaders()
      .set(
        'authorization',auth_Key.auth_key
        )
        return this.http.get(this.baseUrl + url, { headers }); // hitting http get request
    }
  }

  // this function is used for sending delete request
  delete(url) {
    const user: any = localStorage.getItem("userDetails");  // getting user from local storage
    if (user) {
      const auth_Key = JSON.parse(user);
      const headers = new HttpHeaders()
      .set(
        'authorization', auth_Key.auth.key
        )
        return this.http.delete(this.baseUrl + url, { headers });
    }
  }

  // this function is used for sending post request
  post(url, data) {
    const headers = new HttpHeaders(); // create HttpHeader object and set Api-Key in header
    return this.http.post(this.baseUrl + url, data, { headers }); // hitting http post request
  }

}
