import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../environments/environment';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";


@Injectable()
export class HttpleverageSiteService {
     // api base url 
  private baseUrl = environment.student;
  private infoBaseUrl = environment.platform
  private account=environment.account
  private leverageSite=environment.leverageSite

  constructor(private http: HttpClient) {}

  createAuthHeader(headers: HttpHeaders) {
    // headers.append('Api-Key', '34567890');
    // http://localhost:4200/auth/reset-password?token=QlIzaNnvm_bUS0HziXae5zMeHLrQFeQY_1515749972
  }

  // this function is used for sending get request
  get(url) {
    const headers = new HttpHeaders(); // create HttpHeader object and set Api-Key in header
    return this.http.get(this.leverageSite + url, { headers }); // hitting http get request
  }

  getWithAuthorization(url) {
    const user: any = localStorage.getItem("userDetails"); // getting user from local storage
    if (user) {
      const auth_Key = JSON.parse(user);
      const headers = new HttpHeaders()
        .set(
          'authorization', auth_Key.auth_key
          )
      return this.http.get(this.leverageSite + url, { headers }); // hitting http get request
    }
  }
  post(url) {
    const headers = new HttpHeaders(); // create HttpHeader object and set Api-Key in header
    return this.http.post(this.leverageSite + url, { headers }); // hitting http post request
  }

  markAsAnswred(url,data) {
    const headers = new HttpHeaders(); // create HttpHeader object and set Api-Key in header
    return this.http.post(this.leverageSite + url, data, { headers }); // hitting http post request
  }

  answerQuery(url,data) {
    const headers = new HttpHeaders(); // create HttpHeader object and set Api-Key in header
    return this.http.post(this.leverageSite + url, data, { headers }); // hitting http post request
  }

  postWithAuthorization(url, data) {   
    const user: any = localStorage.getItem("userDetails"); // getting user from local storage
  if (user) {
    const auth_Key = JSON.parse(user);
    const headers = new HttpHeaders()
      .set(
        'authorization', auth_Key.auth_key
        )
    return this.http.post(this.leverageSite + url, data, { headers }); // hitting http post request
      }
  }

  addNewPostByManager(url, data) {   
    const user: any = localStorage.getItem("userDetails"); // getting user from local storage
  if (user) {
    const auth_Key = JSON.parse(user);
    let headers = new HttpHeaders()
      .set(
        'authorization', auth_Key.auth_key
        )
      //  headers = headers.append('Content-Type', 'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL');
    return this.http.post(this.leverageSite + url, data, { headers }); // hitting http post request
      }
  }

  getMemberMail(url) {
    return this.http.get(this.leverageSite + url);
  }

  approvePost(url) {   
    const user: any = localStorage.getItem("userDetails"); // getting user from local storage
    if (user) {
      const auth_Key = JSON.parse(user);
      const headers = new HttpHeaders()
        .set(
          'authorization', auth_Key.auth_key
          )
      return this.http.post(this.leverageSite + url,{}, { headers }); // hitting http post request
        }
     
  }

  rejectPost(url) {   
    const user: any = localStorage.getItem("userDetails"); // getting user from local storage
    if (user) {
      const auth_Key = JSON.parse(user);
      const headers = new HttpHeaders()
        .set(
          'authorization', auth_Key.auth_key
          )
      return this.http.post(this.leverageSite + url,{}, { headers }); // hitting http post request
        }
     
  }

}
