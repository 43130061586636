import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../environments/environment';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";

@Injectable()
export class HttpReferClientService {
 
  // api base url 
  private baseUrl = environment.referralManagment;
  private infoBaseUrl = environment.platform
  private account=environment.account

  constructor(private http: HttpClient) {}

  createAuthHeader(headers: HttpHeaders) {
    // headers.append('Api-Key', '34567890');
    // http://localhost:4200/auth/reset-password?token=QlIzaNnvm_bUS0HziXae5zMeHLrQFeQY_1515749972
  }

  // this function is used for sending get request
  get(url) {
    const headers = new HttpHeaders(); // create HttpHeader object and set Api-Key in header
    return this.http.get(this.baseUrl + url, { headers }); // hitting http get request
  }

  // this function is used for sending delete request
  delete(url) {
    const headers = new HttpHeaders(); // create HttpHeader object and set Api-Key in header
    return this.http.delete(this.baseUrl + url, { headers }); // hitting http get request
  }

  // this function is used for sending post request
  post(url, data) {
    const headers = new HttpHeaders(); // create HttpHeader object and set Api-Key in header
    return this.http.post(this.baseUrl + url, data, { headers }); // hitting http post request
  }
  getWithAuthorization(url) {
    const user: any = localStorage.getItem("userDetails"); // getting user from local storage
    if (user) {
      const auth_Key = JSON.parse(user);
      const headers = new HttpHeaders()
        .set(
          'authorization', auth_Key.auth_key
          )
      return this.http.get(this.baseUrl + url, { headers }); // hitting http get request
    }
  }

  postWithAuthorization(url, data) {   
    const user: any = localStorage.getItem("userDetails"); // getting user from local storage
  if (user) {
    const auth_Key = JSON.parse(user);
    const headers = new HttpHeaders()
      .set(
        'authorization', auth_Key.auth_key
        )
    return this.http.post(this.baseUrl + url, data, { headers }); // hitting http post request
      }
  }
  postWithAuthorization2(url, data) {   
    const user: any = localStorage.getItem("userDetails"); // getting user from local storage
  if (user) {
    const auth_Key = JSON.parse(user);
    const headers = new HttpHeaders()
      .set(
        'authorization', auth_Key.auth_key
        )
    return this.http.post(this.infoBaseUrl + url, data, { headers }); // hitting http post request
      }
  }
  deleteWithAuthorization(url) {
    const user: any = localStorage.getItem("userDetails"); // getting user from local storage
    if (user) {
      const auth_Key = JSON.parse(user);
      const headers = new HttpHeaders()
        .set(
          'authorization',auth_Key.auth_key
          )
    return this.http.delete(this.baseUrl + url, { headers }); // hitting http get request
        }
  }

  getAllSchools(url) {
    return this.http.get(this.infoBaseUrl + url);
  }

  coachData(url) {
    return this.http.get(this.baseUrl + url);
  }

  
  updateSchoolMainCampus(url, data) {
    const user1: any = localStorage.getItem("userDetails"); // getting user from local storage
    if (user1) {
      const auth_Key = JSON.parse(user1);
      const headers = new HttpHeaders()
        .set(
          'authorization', auth_Key.auth_key
        )
      return this.http.post(this.infoBaseUrl + url,data ,{ headers }); // hitting http get request
    }
  }
  updateParentId(url, data) {
    const user2: any = localStorage.getItem("userDetails"); // getting user from local storage
    if (user2) {
      const auth_Key = JSON.parse(user2);
      const headers = new HttpHeaders()
        .set(
          'authorization', auth_Key.auth_key
        )
      return this.http.post(this.baseUrl + url,data ,{ headers }); // hitting http get request
    }
  }
  updateBoothSize(url, data) {
    const user3: any = localStorage.getItem("userDetails"); // getting user from local storage
    if (user3) {
      const auth_Key = JSON.parse(user3);
      const headers = new HttpHeaders()
        .set(
          'authorization', auth_Key.auth_key
        )
      return this.http.post(this.baseUrl + url,data ,{ headers }); // hitting http get request
    }
  }
  updateSortOrder(url, data) {
    const user4: any = localStorage.getItem("userDetails"); // getting user from local storage
    if (user4) {
      const auth_Key = JSON.parse(user4);
      const headers = new HttpHeaders()
        .set(
          'authorization', auth_Key.auth_key
        )
      return this.http.post(this.baseUrl + url,data ,{ headers }); // hitting http get request
    }
  }

  updateBoothNumber(url, data) {
    const user5: any = localStorage.getItem("userDetails"); // getting user from local storage
    if (user5) {
      const auth_Key = JSON.parse(user5);
      const headers = new HttpHeaders()
        .set(
          'authorization', auth_Key.auth_key
        )
      return this.http.post(this.baseUrl + url,data ,{ headers }); // hitting http get request
    }
  }

  updateCalEventsBooth(url, data) {
    return this.http.post(this.account + url, data);
  }
  getNonUniUsers(url){
    return this.http.get(this.account + url);
  }
  getSchools(url) {
    return this.http.get(this.infoBaseUrl + url);
  }

  getNonUnivSchool(url) {
    return this.http.get(this.baseUrl + url);
  }

  getLeverageTvEventHost(url) {
    return this.http.get(this.baseUrl + url);
  }
  getLeverageTvHostList(url) {
    return this.http.get(this.baseUrl + url);
  }
  addLeverageTvEventHost(url, data) {
     return this.http.post(this.baseUrl + url,data)
  }
  
  getLeverageTvVideosList(url) {
    return this.http.get(this.infoBaseUrl + url);
  }

  deleteLeverageTvVideos(url) {
    return this.http.delete(this.infoBaseUrl + url)
  }
  addLeverageTvVideo(url, data) {
     return this.http.post(this.infoBaseUrl + url,data)
  }

  updateLeverageTvVideo(url, data) {
     return this.http.put(this.infoBaseUrl + url,data)
  }

  getLeverageTvPlaylistThumbnail(url) {
    return this.http.get(this.infoBaseUrl + url);
  }

  addLeverageTvPlaylistThumbnail(url, data) {
    return this.http.post(this.infoBaseUrl + url,data)
  }
  updateLeverageTvPlaylistThumbnail(url, data) {
     return this.http.put(this.infoBaseUrl + url,data)
  }
  deleteLeverageTvPlaylistThumbnail(url) {
    return this.http.delete(this.infoBaseUrl + url)
  }
}

