import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardRoutingModule } from 'src/app/dashboard/dashboard-routing.module';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  public username: any;
  public user: any;  


  constructor(
        private router : Router, 
    ) { 
          this.user = JSON.parse(localStorage.getItem('userDetails')); 
          // console.log(this.user)
    }

  ngOnInit() {
  }

  userLogout(){
    // console.log("come in logout");
    let details = JSON.parse(localStorage.getItem('userDetails')); 
    this.username = details.username;
    var id = (details.id);
    localStorage.removeItem('userDetails');
    sessionStorage.clear();
    this.router.navigate(['/']);
   }

} 
