import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardRoutingModule } from 'src/app/dashboard/dashboard-routing.module';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-sidenavbar',
  templateUrl: './sidenavbar.component.html',
  styleUrls: ['./sidenavbar.component.css']
})
export class SideNavbarComponent implements OnInit{

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

    public username: any;
  public user: any;  


  constructor(private breakpointObserver: BreakpointObserver, private router : Router,) {
    this.user = JSON.parse(localStorage.getItem('userDetails')); 
    // console.log(this.user)
  }

  
  ngOnInit() {
   this.dropdownBtn();
  }

  userLogout(){
    let details = JSON.parse(localStorage.getItem('userDetails')); 
    this.username = details.username;
    var id = (details.id);
    localStorage.removeItem('userDetails');
    this.user = null;
    details = null;
    sessionStorage.clear();
    this.router.navigate(["/"]).then(result=>{window.location.reload();})
    //this.router.navigate(['/'])
    //location.reload();
   }


   public dropdown:any;
public i;
public dropdownContent;
 dropdownBtn(){
this.dropdown = document.getElementsByClassName("dropdown-btn");
for (this.i = 0; this.i < this.dropdown.length; this.i++) {
  this.dropdown[this.i].addEventListener("click", function() {
  this.classList.toggle("active");
   this.dropdownContent = this.nextElementSibling;
  if (this.dropdownContent.style.display === "block") {
  this.dropdownContent.style.display = "none";
  } else {
  this.dropdownContent.style.display = "block";
  }  
  });
}
}

}
