import { BrowserModule, Title} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientService } from './http-client.service';
import { HttpPrtalLoginService } from './http-portalLogin.service';
import { AlertService } from 'src/app/-services/alert.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { ErrorComponent } from './error/error.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { HttpProductClientService } from "./http-client-product.service";
import { HttpEventsClientService } from './http-client-events.service';
import {HttpLeverageAppService} from './http-client-leverage-app.service';
import {HttpleverageSiteService} from './http-client-leverage-site.service';

import {HttpReferClientService} from './http-client-refer.service';
import { HttpClientAccountService } from './http-client-account.service';
import { NgSelect2Module } from 'ng-select2';
import { SharedModule } from "./shared/shared.module";
import { SideNavbarComponent } from './_layout/sidenavbar/sidenavbar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule } from '@angular/material';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    SideNavbarComponent,
  ],
  imports: [
    BrowserModule, 
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgSelectModule,
    ToastrModule.forRoot(),
    NgSelect2Module,
    SharedModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    NgbModule,
    MatProgressSpinnerModule
  ],
  providers: [Title, HttpClientService, HttpPrtalLoginService, HttpClientAccountService, HttpEventsClientService,HttpLeverageAppService,HttpleverageSiteService,HttpReferClientService, HttpProductClientService, AlertService, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent] 
})
export class AppModule { }
