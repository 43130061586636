import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { AuthGuardService } from './auth-guard.service';

// var user = JSON.parse(localStorage.getItem('userDetails'));
// const isIntern = user.email==='dataintern@leverageedu.com'?true:false;

const routes: Routes = [

  { path: 'error', component: ErrorComponent, pathMatch: 'full' },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'university', canActivate:[AuthGuardService], loadChildren: './dashboard/dashboard.module#DashboardModule' },
  { path: 'auth', loadChildren: './auth/auth.module#AuthModule' },
  { path: 'users', loadChildren: './users/users.module#UsersModule' },
  { path: 'experts', loadChildren: './experts/experts.module#ExpertsModule' },
  { path: 'careers', loadChildren: './careers/careers.module#CareersModule' },
  { path: 'reviews', loadChildren: './reviews/reviews.module#ReviewsModule' },
  { path: 'master', loadChildren: './master/master.module#MasterModule' },
  { path: 'products', loadChildren: './product/product.module#ProductModule' },
  { path: 'pages', loadChildren: './founders/founders.module#FoundersModule' },
  { path: 'coupons', loadChildren: './coupons/coupons.module#CouponsModule' },
  { path: 'tools', loadChildren: './tools/tools.module#ToolsModule' },
  { path: 'events', loadChildren: './events/events.module#EventsModule' },
  { path: 'tvvideo', loadChildren: './tv-videos/tv-vidoes.module#TvVidoesModule' },
  { path: 'tvplaylist', loadChildren: './tv-playlist/tv-playlist.module#TvPlaylistModule' },
  { path: 'leverage-app', loadChildren: './leverage-app/leverage-app.module#LeverageAppModule' },
  { path: 'data-management', loadChildren: './data-management/data-management.module#DataManagementModule'},
  {path : 'refer', loadChildren : './refer/refer.module#ReferModule'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { } 
