/**
 * Created by LG on 11/21/2017.
 */

export class Alert {
    type: AlertType;
    message: string;
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}
